import { isNullOrUndefined } from 'util';

export class UserLogin {
    public captchaValue: string;
    public password: string;
    public scope: string;
    public type: number;
    public cnpj: string;
    public cpf: string;

    constructor(captchaValue: string, password: string, scope: string,
                type: number, cnpj: string, cpf: string) {

        this.captchaValue = captchaValue;
        this.password = btoa(password);
        this.scope = scope;
        this.type = type;
        this.cnpj = !isNullOrUndefined(cnpj) ? btoa(cnpj) : '';
        this.cpf = !isNullOrUndefined(cpf) ? btoa(cpf) : '';
    }
}
