import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { WarningLevel } from 'src/app/framework/enums';
import { ContextService } from 'src/app/services/application/context.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { FirstAccessSteps } from '../../../models/first-access-steps.enum';
import { FirstAccessToken } from '../../../models/first-access-token.model';
import { FirstAccessService } from '../../../services/first-access.service';
import { NavigationService } from '../../../services/navigation.service';
import { PortalTokenTime } from 'src/app/models/PortalTokenTime';
import { TokenTimePortal } from 'src/app/models/iscope';

@Component({
  selector: 'app-first-access-token',
  templateUrl: './first-access-token.component.html',
  styleUrls: ['./first-access-token.component.scss']
})
export class FirstAccessTokenComponent implements OnInit {

  counter: { min: number, sec: number };

  public scopeKey: string;
  public countDownTimer: string;

  private _attempt = false;
  private requesting = false;

  get textVerifyIdentity(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.textVerifyIdentity;
    }
    return '';
  }

  get instructionsVerifyIdentity(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.instructionsVerifyIdentity;
    }
    return '';
  }

  constructor(
    private firstAccessService: FirstAccessService,
    public navigationService: NavigationService,
    private messageService: MessageSwalService,
    private context: ContextService) {
    this.scopeKey = this.context.authService.currentScope.key;
  }

  get contact(): string {
    return this.navigationService.form.get('email').value;
  }

  get attempt(): boolean {
    return this._attempt;
  }

  get token(): AbstractControl { return this.navigationService.form.get('token'); }

  get time(): string {
    return this.timeWithHours;
  }

  get timeWithHours(): string {
    return this.countDownTimer;
  }

  get isRequesting() {
    return this.requesting;
  }

  get tokenTimeDescription() {
    return PortalTokenTime.description(this.context.authService.currentScope.portalTokenTime || TokenTimePortal.FIFTEEN_MINUTUES);
  }

  ngOnInit() {
    this._attempt = false;
    this.startTimer();
  }

  async verifyToken(): Promise<void> {
    this._attempt = true;
    if (this.navigationService.form.invalid) {
      return;
    }

    this.requesting = true;
    const model = new FirstAccessToken(
      this.context.authService.currentScope.key,
      '',
      this.navigationService.userId,
      this.token.value
    );

    await this.firstAccessService.verifyToken(model)
      .toPromise()
      .then(response => {
        this.success(response, FirstAccessSteps.CadastrarSenha);
        this.requesting = false;
      })
      .catch(error => {
        this.error(error);
        this.requesting = false;
      });
  }

  previous() {
    this.navigationService.clearValidationsForm();

    if (this.navigationService.recoveryPassword) {
      this.navigationService.etapa -= 2;
    } else {
      this.navigationService.etapa -= 1;
    }
  }

  startTimer() {
    if (this.navigationService.expirationToken) {
      var expirationTimeDate = new Date(this.navigationService.expirationToken);

      var expirationTime = expirationTimeDate.getTime();

      var timeNow = Date.now();

      if (timeNow < expirationTime) {
        this.startTimerWithHours(expirationTime);
      }
    }
  }

  startTimerWithHours(expirationTime: any) {
    if (this.navigationService.expirationToken) {
      var timer = expirationTime, hours, minutes, seconds;
      const intervalId = setInterval(() => {

        let diff = (((timer - Date.now()) / 1000) | 0);

        hours = (diff / 3600) | 0;
        minutes = ((diff % 3600) / 60) | 0;
        seconds = (diff % 60) | 0;

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.countDownTimer = hours + ":" + minutes + ":" + seconds;
        if (--diff < 0) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }

  checkValidity(control: AbstractControl) {
    return this._attempt && control.valid;
  }

  checkInvalidity(control: AbstractControl) {
    return this._attempt && control.invalid;
  }

  public showPhones() {

    let content: string;

    if (this.scopeKey !== 'hedge') {
      content = this.context.authService.currentScope.scopeContact;
    } else {
      content = '<div class="mt-2">HEDGE INVESTMENTS</div><br/>' +
        '<div class="mt-1">FALE CONOSCO</div>' +
        '<div class="mt-1 font-size-15"><a class="font-color-gray" href="mailto:informederendimentos@hedgeinvest.com.br">informederendimentos@hedgeinvest.com.br</a></div>';
    }

    this.messageService.displayMessage({ warninglevel: WarningLevel.Info, message: content }, () => { });
  }

  public showInstructionsVerifyIdentity() {
    this.messageService.displayMessage({ warninglevel: 3, message: this.instructionsVerifyIdentity }, () => { });;
  }

  private success(guid: any, nextStep: number): void {
    this.navigationService.etapa = nextStep;
    this.navigationService.userId = guid;
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
  }

}
