import { IEntity } from "./ientity"

export interface IAuthProvider {
    providerType: number,
    sharepointBaseUrl: string
}

export interface IStorage {
    storageType: number,
    storageUri: string,
    storagePath: string,
    hideMenu: string
}

export enum TokenTimePortal {
  FIFTEEN_MINUTUES = 1,
  TWENTY_FOUR_HOURS = 2,
}

export interface IScope extends IEntity {
    key: string,
    name: string,
    domain: string,
    databaseProvider: string,
    provider: IAuthProvider,
    storage: IStorage,
    title: string,
    baseColor: string,
    brandImageUrl: string,
    backgroundImageUrl: string,
    theme: string,
    scopeContact: string,
    alternativePortalDomain: string
    homePageImageUrl: string,
    showLoginScopeLogo: boolean,
    loginPageWelcomeText:string,
    welcomeTextSidebar:string
    firstAccessTextLink: string,
    portalTokenTime?: TokenTimePortal,
    textVerifyIdentity: string,
    instructionsVerifyIdentity: string
}
