import { VerifyIdentity } from '../verify-identity.model';

export class FirstAccessIdentity extends VerifyIdentity {
    public nascimento: string;
    public fundacao: string;

    constructor(
        scope: string,
        captchaValue: string,
        userType: number,
        cpf: string,
        nascimento: string,
        cnpj: string,
        fundacao: string) {
        super(scope, captchaValue, userType, cpf, cnpj);
        this.nascimento = nascimento;
        this.fundacao = fundacao;
    }
}
