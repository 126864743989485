export class ModelBase {
    scope: string;
    captchaValue: string;

    constructor(scope: string, captchaValue: string) {
        this.scope = scope;
        this.captchaValue = captchaValue;
    }

}
