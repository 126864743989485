import { TokenTimePortal } from "./iscope";

export class PortalTokenTime {
  public static description(value: TokenTimePortal): string {
    if (value === TokenTimePortal.FIFTEEN_MINUTUES)
      return "15 minutos";
    else if (value === TokenTimePortal.TWENTY_FOUR_HOURS)
      return "24 horas";

      return "15 minutos";
  }
}
