import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ContextService } from 'src/app/services/application/context.service';
import { MessageSwalService } from '../../../../../services/application/messageswal.service';
import { FirstAccessSecurity } from '../../../models/first-access/first-access-security.model';
import { FirstAccessService } from '../../../services/first-access.service';
import { FirstAccessSteps } from '../../../models/first-access-steps.enum';
import { NavigationService } from '../../../services/navigation.service';
import { WarningLevel } from 'src/app/framework/enums';

@Component({
  selector: 'app-first-access-security',
  templateUrl: './first-access-security.component.html',
  styleUrls: ['./first-access-security.component.scss']
})
export class FirstAccessSecurityComponent implements OnInit {

  public scopeKey: string;
  private requesting = false;

  get userType(): AbstractControl { return this.navigationService.form.get('userType'); }
  get email(): AbstractControl { return this.navigationService.form.get('email'); }

  get firstAccessTextLink(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.firstAccessTextLink;
    }
    return '';
  }

  constructor(
    private firstAccessService: FirstAccessService,
    public navigationService: NavigationService,
    private messageService: MessageSwalService,
    private context: ContextService,) {
    this.scopeKey = this.context.authService.currentScope.key;
  }

  get isRequesting() {
    return this.requesting;
  }

  ngOnInit() {
  }

  async sendToken(): Promise<void> {
    if (this.navigationService.form.invalid) {
      return;
    }

    this.requesting = true;
    const security = new FirstAccessSecurity(
      this.context.authService.currentScope.key,
      '',
      +this.userType.value,
      this.navigationService.contacts.id,
      this.email.value
    );

    await this.firstAccessService.verifySecurity(security)
      .toPromise()
      .then(response => {
        this.success(response.userId, FirstAccessSteps.VerificarIdentidade, false, response.expirationToken);
      })
      .catch(error => {
        this.error(error);
        this.requesting = false;
      });
  }

  public calledCentral() {
    this.success(this.navigationService.contacts.id, FirstAccessSteps.VerificarIdentidade, true);
  }

  private success(guid: any, nextStep: number, tokenGeneratedByExternalRequest: boolean, expirationToken: Date = null): void {
    this.navigationService.etapa = nextStep;
    this.navigationService.userId = guid;
    this.navigationService.tokenGeneratedByExternalRequest = tokenGeneratedByExternalRequest;
    this.requesting = false;
    this.navigationService.expirationToken = expirationToken;
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
  }

  public showPhones() {
    let content: string;

    if (this.scopeKey !== 'hedge') {
      content = this.context.authService.currentScope.scopeContact;
    } else {
      content = '<div class="mt-2">HEDGE INVESTMENTS</div><br/>' +
      '<div class="mt-1">FALE CONOSCO</div>' +
      '<div class="mt-1 font-size-15"><a class="font-color-gray" href="mailto:informederendimentos@hedgeinvest.com.br">informederendimentos@hedgeinvest.com.br</a></div>';
    }

    this.messageService.displayMessage({ warninglevel: WarningLevel.Info, message: content }, () => { });
  }
}
