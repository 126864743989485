import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ContextService } from './services/application/context.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'personalichannel-client-app';
  constructor(private activatedRoute: ActivatedRoute, private context: ContextService) {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    if(window.location.hash.includes('redirect')) {
      this.context.authService.scopeKey = window.location.hash.split('/')[2];
    } else {
      this.context.authService.scopePortalDomain = window.location.hostname;
    }

    if(!environment.isHomolog && !environment.production) {
      this.context.authService.scopeKey = "riobravo";
    }

  }
}
