import { Injectable } from "@angular/core";

import { Storage } from "../../framework/storage";

import { IAppRole } from "../../models/IAppRole";

import * as FW from "../../framework/core";
import { Session } from 'src/app/framework/session';
import { IScope } from 'src/app/models/iscope';
import { NavigationService } from "./navigation.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    
    constructor(private navigation: NavigationService) {
    }


    public userDocument: string = null;
    public userGUID: string = null;
    public userName: string = null;
    public userEmail: string = null;
    public userPhone: string = null;
    public userPermission: number = null;
    public userRole: number = null;
    public isSimulation: boolean = false;

    private _currentScope: IScope = null;
    private _authProvider: "ONXRM" | "Microsoft" = null;

    private _authToken: string = null;
    private _expiresAt: Date = null;

    private _appRoles: Array<IAppRole> = null;
    
    private _scopeKey : string;
    
    public scopePortalDomain : string;

    public get scopeKey() : string {
        if (FW.isNullOrEmpty(this._scopeKey)) {
            if(!FW.isNullOrEmpty(Storage.getString("scopeKey"))) {
                this._scopeKey = Storage.getString("scopeKey");
            }
        }
        return this._scopeKey;
    }

    public set scopeKey(value : string) {
        if(this._scopeKey !== value) {
            Storage.setString("scopeKey", value);
            this._scopeKey = value;
        }
    }
    

    public get currentScope(): IScope {
        if (FW.isNullOrEmpty(this._currentScope)) {
            if(!FW.isNullOrEmpty(Storage.getString("currentScope"))) {
                this._currentScope = JSON.parse(Storage.getString("currentScope"));
            }
        }
        if(this._currentScope && (this._currentScope.key == "riobravo" || this._currentScope.key == "validdemonstracao"
        || this._currentScope.key == "hedge"))
            this._currentScope.name = "";
        return this._currentScope;
    }

    public get getUserDocument(): string {
        if (FW.isNullOrEmpty(this.userDocument)) {
            this.userDocument = Storage.getString("userDocument");
        }
        return this.userDocument;
    }

    public set setUserDocument(value: string) {
        this.userDocument = value;
        Storage.setString("userDocument", this.userDocument);
    }

    public set currentScope(value: IScope) {
        if (value != this._currentScope) {
            Storage.setString("currentScope", JSON.stringify(value));
            this._currentScope = value;
        }
    }

    public get authProvider(): "ONXRM" | "Microsoft" {
        if (FW.isNullOrBlank(this._authProvider)) {
            this._authProvider = <"ONXRM" | "Microsoft">Storage.getString("authProvider");
        }
        return this._authProvider;
    }

    public set authProvider(value: "ONXRM" | "Microsoft") {
        if (value != this._authProvider) {
            Storage.setString("authProvider", value);
            this._authProvider = value;
        }
    }

    public get appRoles(): Array<IAppRole> {
        if (FW.isNullOrEmpty(this._appRoles)) {
            this._appRoles = [
                { id: 3, name: "Convidado" },
                { id: 15, name: "Usuário Comum" },
                { id: 33, name: "Gestor" },
                { id: 127, name: "Administrador" }
            ];
        }

        return this._appRoles;
    }

    public get authToken(): string {
        if (FW.isNullOrBlank(this._authToken)) {
            this._authToken = Storage.getString("auth_token", null);
        }
        return this._authToken;
    }

    public set authToken(value: string) {
        this._authToken = value;
        Storage.setString("auth_token", this._authToken);
    }

    public get expiresAt(): Date {
        if (FW.isNull(this._expiresAt)) {
            this._expiresAt = Storage.getDate("expires_at");
        }
        return this._expiresAt;
    }

    public set expiresAt(value: Date) {
        this._expiresAt = value;
        Storage.setDate("expires_at", this._expiresAt);
    }

    public get hasSession(): boolean {
        return !FW.isNullOrBlank(this.authToken);
    }

    public get isSessionActive(): boolean {
        return this.hasSession && (this.expiresAt > (new Date()));
    }

    public tryToRestoreSession(): boolean {
        const storedUserGUID: string = Storage.getString("userguid");

        if (this.hasSession && !FW.isNullOrBlank(storedUserGUID)) {
            this.restoreSession();
            return true;
        } else {
            return false;
        }
    }

    public restoreSession(): void {
        const storedUserGUID: string = Storage.getString("userguid");
        const storedUserName: string = Storage.getString("username");
        const storedUserEmail: string = Storage.getString("useremail");
        const storedUserPhone: string = Storage.getString("userphone");
        const storedUserPermission: number = Storage.getNumber("userpermission");
        const storedUserRole: number = Storage.getNumber("userrole");
        const storedIsSimulation: number = Storage.getNumber("isSimulation");

        this.updateSession(storedUserGUID, storedUserName, storedUserEmail, storedUserPhone, storedUserPermission, storedUserRole, storedIsSimulation == 1);
    }

    public updateSession(guid: string, name: string, email: string, phone: string, permission: number, role: number, isSimulation: boolean): void {
        this.userGUID = guid;
        this.userName = name;
        this.userEmail = email;
        this.userPhone = phone;
        this.userPermission = permission;
        this.userRole = role;
        this.isSimulation = isSimulation;
    }

    public initSession(sessionToken: string, sessionTimeout: Date, guid: string, name: string, email: string, phone: string, permission: number, role: number, isSimulation?: boolean): void {
        this.authToken = sessionToken;
        this.expiresAt = sessionTimeout;

        Storage.setString("userguid", guid);
        Storage.setString("username", name);
        Storage.setString("useremail", email);
        Storage.setString("userphone", phone);
        Storage.setNumber("userpermission", permission);
        Storage.setNumber("userrole", role);
        this.isSimulation = isSimulation;
        if (this.isSimulation)
            Storage.setNumber("isSimulation", 1);
        else
            Storage.setNumber("isSimulation", 0);

        this.updateSession(guid, name, email, phone, permission, role, this.isSimulation);
    }

    public logoff(): void {
        var division = Storage.getString("currentScope");
        var scopeKey = Storage.getString("scopeKey");
        Storage.clear();
        Session.clear();
        if(FW.isNullOrBlank(scopeKey) && this.navigation.hasQueryParameter("scope")) {
            scopeKey = this.navigation.getQueryParameter("scope");
        }

        if(!FW.isNullOrBlank(scopeKey) && 
        this.navigation.hasQueryParameter("scope") &&
        this.navigation.getQueryParameter('scope') !== scopeKey) {
            scopeKey = this.navigation.getQueryParameter("scope");
        }

        Storage.setString("currentScope",division);
        Storage.setString("scopeKey",scopeKey);
        if(environment.isHomolog && !FW.isNullOrBlank(scopeKey)) {
            window.location.href = `#/login?scope=${scopeKey}`;
        } else {
            window.location.href = '#/login';
        }
    }

    public requireRole(level: number, role?: number): boolean {
        if (FW.isNull(role)) { role = this.userRole; }
        return (level & role) == level;
    }

    public verifyPermission(privilege: number, permission?: number): boolean {
        if (FW.isNull(permission)) { permission = this.userPermission; }
        return (privilege & permission) > 0;
    }
}