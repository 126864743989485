import { Component, OnInit } from '@angular/core';
import { ContextService } from 'src/app/services/application/context.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public userName: string;
  public userEmail: string;
  public scopeLogo: string;
  public scopeName: string;
  public sector: { name: string, brandImageUrl: any } = { name: null, brandImageUrl: null }
  public sectorImageUrl: string;

  public userRole: number;
  public scope: any;

  public canSetAlternativeEmail: boolean;
  get welcomeTextSideBar(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.welcomeTextSidebar;
    }
    return '';
  }

  constructor(private router: Router, private context: ContextService) {

    this.userName = this.context.authService.userName;
    this.userEmail = this.context.authService.userEmail;
    this.userRole = Number(sessionStorage.getItem('userrole'));

    this.scope = this.context.authService.currentScope;
    if (this.scope) {
      this.scopeLogo = this.scope.brandImageUrl;
      this.scopeName = this.scope.name;
      this.canSetAlternativeEmail = this.scope.canSetAlternativeEmail;
    }
    if (this.sector == null) {
      this.router.navigate(['/login']);
    } else {
      this.sectorImageUrl = this.sector.brandImageUrl;
      this.sector.name = this.welcomeTextSideBar != null && this.welcomeTextSideBar != '' ? this.welcomeTextSideBar : 'Bem-vindo(a)';
    }
  }

  ngOnInit() {
  }

  hideMenus(menuName: string): boolean {
    if (this.scope.storage.hideMenu) {
      return !this.scope.storage.hideMenu.includes(menuName);
    }
    return true;
  }

  permissionMaster() {
    if (this.userRole === 255) {
      return true;
    }
    return false;
  }
  permissionAdmin() {
    if (this.userRole >= 127) {
      return true;
    }
    return false;
  }

}
