import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpErrorHandler, HttpErrorHandleDelegate } from "./httperrorhandler.service";

import { environment } from "../../../environments/environment";

import { IAppContext, IEnvelope } from "../../framework/interfaces";

import * as FW from "../../framework/core";

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root',
})
export class APIService {
    // https://stackblitz.com/angular/ooqemvjyqkb?file=src%2Fapp%2Fapp.module.ts

    private handleHttpError: HttpErrorHandleDelegate = null;

    constructor(private httpClient: HttpClient, private httpErrorHandler: HttpErrorHandler) {
        this.handleHttpError = this.httpErrorHandler.createHttpErrorHandler();
    }

    public get<T>(ctx: IAppContext, url: string): Observable<T> {
        return this.httpClient.get<T>(environment.baseApiUrl + url).
            pipe(
                catchError(this.handleHttpError(ctx, url, null))
            );
    }

    public post<T>(ctx: IAppContext, url: string, postEnvelope: IEnvelope<T>): Observable<IEnvelope<T>> {
        return this.httpClient.post<IEnvelope<T>>(environment.baseApiUrl + url, postEnvelope, httpOptions).
            pipe(
                catchError(this.handleHttpError(ctx, url, postEnvelope))
            );
    }

    public put<T>(ctx: IAppContext, url: string, putEnvelope: IEnvelope<T>): Observable<IEnvelope<T>> {
        const endPointUrl: string = environment.baseApiUrl + url;
        return this.httpClient.put<IEnvelope<T>>(endPointUrl, putEnvelope, httpOptions).
            pipe(
                catchError(this.handleHttpError(ctx, url, putEnvelope))
            );
    }

    public delete(ctx: IAppContext, url: string): Observable<any> {
        return this.httpClient.delete(environment.baseApiUrl + url, httpOptions).
            pipe(
                map<Object, Boolean>(() => { return true; }),
                catchError(this.handleHttpError(ctx, url, false))
            );
    }

    public upload<T>(ctx: IAppContext, url: string, fileName: string, fileData: ArrayBuffer, mimeType: string, lastModified: string, folderId: string): Observable<string> {
        let fileUploadHeaders = {
            'Content-Type': mimeType,
            'x-file-name': fileName,
            'x-last-modified': lastModified
        };

        if (!FW.isNullOrBlank(folderId)) {
            fileUploadHeaders["x-folder-id"] = folderId;
        }

        const fileUploadHttpOptions = { headers: new HttpHeaders(fileUploadHeaders) };

        return this.httpClient.post<string>(environment.baseApiUrl + url, fileData, fileUploadHttpOptions).
            pipe(
                catchError(this.handleHttpError(ctx, url, null))
            );
    }

    public mountSearchUrl(endPoint: string, filter: string, orderBy: string, desc?: boolean, limit?: number, skip?: number) {
        let url: string = endPoint;

        if (!FW.isNull(limit)) {
            url += "/" + limit.toString();
        }

        if (!FW.isNull(skip)) {
            url += "/" + skip.toString();
        }

        if (FW.isNullOrBlank(filter)) { filter = ""; }

        url += "?filter=" + encodeURIComponent(filter);

        if (!FW.isNullOrBlank(orderBy)) {
            url += "&orderby=" + encodeURIComponent(orderBy);
        }

        url += "&desc=" + (desc ? "1" : "0");

        return url;
    }

    public getEnvelopeContent<T>(response: any): T {
        let envelope: IEnvelope<T> = response as IEnvelope<T>;

        if (!FW.isNull(envelope.content)) {
            return envelope.content;
        } else {
            return <T><any>envelope;
        }
    }
}
