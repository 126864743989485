import { ModelBase } from 'src/app/models/base.model';
import { isNullOrUndefined } from 'util';

export abstract class VerifyIdentity extends ModelBase {
    public userType: number;
    public cpf: string;
    public cnpj: string;

    constructor(
        scope: string,
        captchaValue: string,
        userType: number,
        cpf: string,
        cnpj: string) {
        super(scope, captchaValue);
        this.userType = userType;
        this.cpf =  !isNullOrUndefined(cpf) ? btoa(cpf) : '';
        this.cnpj =  !isNullOrUndefined(cnpj) ? btoa(cnpj) : '';
    }
}
