import { Guid } from 'guid-typescript';
import { ModelBase } from '../../../models/base.model';

export class FirstAccessToken extends ModelBase {
    public id: Guid;
    public token: string;

    constructor(
        scope: string,
        captchaValue: string,
        id: Guid,
        token: string) {
        super(scope, captchaValue);
        this.id = id;
        this.token = token;
    }
}
