import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/security/login/login.component';
import { AppControlsModule } from './controls/app-controls.module';
import { ViewLoader } from './services/application/viewloader.service';
import { httpInterceptorProviders } from './http-interceptors/interceptorprovider';
import { serviceProviders } from './services/serviceprovider';
import { HttpClientModule } from '@angular/common/http';
import { LockScreenComponent } from './pages/security/lockscreen/lockscreen.component';
import { FirstAccessComponent } from './components/user/first-access/first-access.component';
import { FirstAccessIdentityComponent } from './components/user/first-access/steps/first-access-identity/first-access-identity.component';
import { FirstAccessSecurityComponent } from './components/user/first-access/steps/first-access-security/first-access-security.component';
import { FirstAccessTokenComponent } from './components/user/first-access/steps/first-access-token/first-access-token.component';
import { FirstAccessPasswordComponent } from './components/user/first-access/steps/first-access-password/first-access-password.component';
import { NgxMaskModule } from 'ngx-mask';
import { MASK_CONFIG } from './app.mask';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { VersionComponent } from './pages/version/version.component';
import { MiddleComponent } from './pages/middle/middle.component';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    LockScreenComponent,
    FirstAccessComponent,
    FirstAccessIdentityComponent,
    FirstAccessSecurityComponent,
    FirstAccessTokenComponent,
    FirstAccessPasswordComponent,
    RedirectComponent,
    MiddleComponent,
    VersionComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppControlsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(MASK_CONFIG)
  ],
  providers: [
    httpInterceptorProviders,
    serviceProviders,
    ViewLoader
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
