import { VerifyIdentity } from '../verify-identity.model';

export class RecoveryPasswordIdentity extends VerifyIdentity {

    constructor(
        scope: string,
        captchaValue: string,
        userType: number,
        cpf: string,
        cnpj: string) {
        super(scope, captchaValue, userType, cpf, cnpj);
    }
}
