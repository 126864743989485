import { Injectable } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { Observable, of } from 'rxjs';
import { WarningLevel } from "../../framework/enums";
import * as FW from "../../framework/core";
import { IProtocol } from 'src/app/models/IProtocol';
import { IAggregator } from 'src/app/models/IAggregator';

@Injectable({
  providedIn: 'root'
})

export class MyDocumentsService extends BaseService {

  private baseUrl: string;
  public get serviceName(): string { return "MyDocuments" };
  constructor(private apiService: APIService, public context: ContextService) {
    super();
    this.baseUrl = "/document";
  }

  search(params: IQueryParams[], idDocument: string): Observable<IProtocol[]> {      
    const callURL = super.fabricateURL(`${this.baseUrl}/search?iddocument=${idDocument}&`, params);
    const ctx: IAppContext = this.context.join(this.serviceName);    
    if (callURL) {
      var retorno = this.apiService.get<IProtocol[]>(ctx, callURL);
      return retorno;
    } else {
      return of(null)
    }
  }

  searchByAggregator(params: IQueryParams[], idDocument: string, idAggregator: string): Observable<IAggregator[]> {      
    const callURL = super.fabricateURL(`${this.baseUrl}/searchByAggregator?iddocument=${idDocument}&aggregator=${idAggregator}&`, params);
    const ctx: IAppContext = this.context.join(this.serviceName);    
    if (callURL) {
      var retorno = this.apiService.get<IAggregator[]>(ctx, callURL);
      return retorno;
    } else {
      return of(null)
    }
  }

  searchAggregators(idDocument: string): Observable<IAggregator[]> {
    const callURL = `${this.baseUrl}/searchAggregators?iddocument=${idDocument}`;
    const ctx: IAppContext = this.context.join(this.serviceName);
    if (callURL) {
      var retorno = this.apiService.get<IAggregator[]>(ctx, callURL);
      return retorno;
    } else {
      return of(null)
    }
  }

  getAggregator(idAggregator: string): Observable<IAggregator[]> {
    const callURL = `${this.baseUrl}/getAggregator?aggregator=${idAggregator}`;
    const ctx: IAppContext = this.context.join(this.serviceName);    
    if (callURL) {
      var retorno = this.apiService.get<IAggregator[]>(ctx, callURL);
      return retorno;
    } else {
      return of(null)
    }
  }

  updateDatePreview(idDocument: string): Observable<FW.Envelope<any>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    return this.apiService.get(ctx, `${this.baseUrl}/alterdocument?iddocument=${idDocument}`)
  }

  sendEmail(messageId: string, recipients: string): Observable<FW.Envelope<any>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    return this.apiService.get(ctx, `${this.baseUrl}/sendemaildocument/${messageId}?recipients=${recipients}`)
  }

  getUrlFile(protocolId): Observable<FW.Envelope<any>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    return this.apiService.get(ctx, `${this.baseUrl}/requestfile?protocolId=${protocolId}`)
  }
}
