import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';

import { Storage } from "../../framework/storage";

import { ContextService } from "../../services/application/context.service";
import { APIService } from "../../services/application/api.service";

import { IScope } from "../../models/iscope";


import { WarningLevel } from "../../framework/enums";
import { IAppContext, IEnvelope } from "../../framework/interfaces";

import * as FW from "../../framework/core";

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    public get serviceName(): string { return "Settings" };

    public appTitle: string = "CODECONECTA";
    public baseColor: string = "#1E2A31";
    public brandImageUrl: string = null;
    public backgroundImageUrl: string = null;

    constructor(public context: ContextService, private api: APIService) {
    }

    public storeSettings(): void {
        Storage.setString("appTitle", this.appTitle);
        Storage.setString("baseColor", this.baseColor);
        Storage.setString("brandImageUrl", this.brandImageUrl);
        Storage.setString("backgroundImageUrl", this.backgroundImageUrl);
    }

    public restoreSettings(): void {
        this.appTitle = Storage.getString("appTitle", "CODECONECTA");
        this.baseColor = Storage.getString("baseColor", "#1E2A31");
        this.brandImageUrl = Storage.getString("brandImageUrl", null);
        this.backgroundImageUrl = Storage.getString("backgroundImageUrl", null);
    }

    public saveThemeSettings(entity: IScope): Observable<IEnvelope<IScope>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Título", entity.title);

        const postEnvelope = new FW.Envelope(entity);

        if (!validator.isValid) {
            postEnvelope.warningLevel = WarningLevel.Warning;
            return of(postEnvelope);
        }

        return this.api.post(ctx, "/settings/scope/save", postEnvelope);
    }
}