import { UserType } from 'src/app/models/user-type.enum';
import { ModelBase } from '../../../../models/base.model';
import { Guid } from 'guid-typescript';
export class FirstAccessSecurity extends ModelBase {
    public userType: UserType;
    public id: Guid;
    public email: string;

    constructor(scope: string, captchaValue: string, userType: UserType, id: Guid, email: string) {
        super(scope, captchaValue);
        this.userType = userType;
        this.id = id;
        this.email = email;
    }
}
